import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { connect } from 'react-redux';
import { HighLigtContainerStyled } from './styled';
import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { setReduxUserAuth } from 'actions';
import { BoxImage } from 'components';
import { ROUTE_PATH, renderImage } from 'helpers';
import { Helmet } from "react-helmet";

class HighLigtContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();

    // Tracking user pageview
    ReactGA.set({ page: this.props.location.pathname });
    ReactGA.pageview(this.props.location.pathname);
    ReactPixel.pageView();
  }

  fetchData = async () => {
    let res = await userService.GET_HIGHLIGHT_ALL_LIST();
    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  handleClickCreatorDetail = (id) => {
    this.props.history.push(`${ROUTE_PATH.CREATOR}/${id}`);
  };

  render() {
    const { data } = this.state;
    return (
      <HighLigtContainerStyled>
        {data && (
          <Helmet>
            <title>highlight</title>
            <meta
              name="og:image"
              content={
                data.posters && data.posters.length > 0
                  ? data.posters[0].uri
                  : null
              }
            />
            <meta name="og:title" content={data.displayname} />
          </Helmet>
        )}
        <div className="text_title">Highlight</div>
        <div className="container">
          {data &&
            data.map((e, i) => (
              <div
                key={i}
                className="item_wrap"
                onClick={() => this.handleClickCreatorDetail(e.id)}
              >
                <BoxImage
                  theme_standard
                  classManual="dynamic"
                  name={e.displayname}
                  detail={e.description}
                  src={e && renderImage(e)}
                  // src={
                  //   (e.posters && e.posters.length > 0 && e.posters[0].uri) ||
                  //   iconImages['Loading.png']
                  // }
                />
              </div>
            ))}
        </div>
      </HighLigtContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighLigtContainer);

import React from 'react';
import { connect } from 'react-redux';
import { CategoriesContainerStyled } from './styled';
import { setReduxUserAuth } from 'actions';
import { userService } from 'apiService';
// import { iconImages } from 'assets';
import { ROUTE_PATH, renderImage, renderServiceId } from 'helpers';
import { BoxImage } from 'components';
import { BannerCategories } from 'widgets';
import { Helmet } from "react-helmet";

class CategoriesContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
    this.fetchCreatorList();
  }

  fetchData = async () => {
    let res = await userService.GET_CATEGORIES_DETAIL(
      this.props.match.params.slug
    );

    if (res && res.success) {
      this.setState({
        data: res.data,
      });
    }
  };

  fetchCreatorList = async () => {
    let res = await userService.GET_CATEGORIES_CREATOR(
      this.props.match.params.slug
    );
    
    if (res && res.success) {
      this.setState({
        creatorList: res.data,
      });
    }
  };

  handleClickCreatorDetail = (e) => {
    
    let service_id = renderServiceId(e)
    // this.props.history.push(`${ROUTE_PATH.CREATOR}/${e.id}/${service_id}`);

    this.props.history.push(`${ROUTE_PATH.CREATOR}/${e.id}${service_id ? `?service_id=${service_id}` : ''}`);
  };

  render() {
    const { data, creatorList } = this.state;
    return (
      <CategoriesContainerStyled>
        {data && (
          <Helmet>
            <title>category - {data && data.label}</title>
            <meta
              name="og:image"
              content={
                data.posters && data.posters.length > 0
                  ? data.posters[0].uri
                  : null
              }
            />
            <meta name="og:title" content={data.displayname} />
          </Helmet>
        )}
        <div className="text_title">{data && data.label}</div>
        <div className="banner_layout">
          <BannerCategories data={data} />
        </div>
        <div className="container">
          {creatorList &&
            creatorList.map((e, i) => (
              <div
                key={i}
                className="item_wrap"
                onClick={() => this.handleClickCreatorDetail(e)}
              >
                <BoxImage
                  theme_standard
                  classManual="dynamic"
                  name={e.displayname}
                  detail={e.description}
                  src={e && renderImage(e)}
                // src={
                //   (e &&
                //     e.posters &&
                //     e.posters.length > 0 &&
                //     e.posters[0].uri) ||
                //   iconImages['Loading.png']
                // }
                />
              </div>
            ))}
        </div>
      </CategoriesContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authRedux: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxUserAuth: (data) => dispatch(setReduxUserAuth(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesContainer);


export const renderServiceId = (e) => {
  if (e) {
    if (e.service) {
      return e.service.id
    } else {
      return null
    }
  } else {
    return null
  }
}
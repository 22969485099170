import { iconImages } from 'assets';

export const renderImage = (e) => {
  if (e) {
    if (e.service && e.service.poster_photo_uri) {
      return e.service.poster_photo_uri
    } else if (e.posters && e.posters.length > 0 && e.posters[0].uri) {
      return e.posters[0].uri
    } else {
      return iconImages['Loading.png']
    }
  } else {
    return iconImages['Loading.png']
  }
}